import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "booked": "Booked",
          "terms_condition": "Terms & Conditions",
          "submit_otp": "Please enter OTP receive on register mobile",
          "otp_placeholder": "Please enter OTP",
          "cancel": "Cancel",
          "resendOtp": "Resend OTP",
          "submit": "Submit",
          "warningAlert": "We strongly recommend you to not share this weblink with anyothers. They might use your reservation on for their own",
          "reservation_details": "Reservation Details",
          "qr_code": "QR code",
          "scan_text_warn": "Present the QR code upon arrival",
          "location": "Location",
          "address": "Address",
          "contact_info": "Contact info",
          "phone": "Phone",
          "website": "Website",
          "menu": "Menu",
          "our_menu": "see our menu",
          "cuisine": "Cuisine or food",
          "facilities": "Facilities",
          "agree_with": "Agree with",
          "check_out": "Check out",
          "reviews": "Reviews",
          "total_review": "Total Reviews",
          "release": "Release",
          "ocuupied": "Ocuupied",
          close: "Close",
          okay: "Okay",
          reservation_id: "Reservation ID",
          zone: "Zone",
          start_at: "Start At",
          end_at: "End At",
          not_allow_open: "Not allow to be opened in desktop, tablet & foldable phones",
          time: "Time",
          guests: "Guests",
          find_us: "How to find us",
          text_copy: "Text copied to clipboard!",
          select_language: "Select language",
          ambiance: "Ambiance",
          food: "Food",
          service: "Service",
          wifi: "Wifi",
          credit_card: "Credit Card",
          kids: "Kids",
          private_parking: "Private parking",
          public_parking: "Public parking",
          power_by: "Power by",
          read: "Read",
          month: "month",
          info1: "",
          info2: "",
          completed: "Completed",
          released: "Released",
          canceled: "Canceled",
          expired: "Expired",

          RegisterOn: "Register on",
          WaitingForAppr: "Wait for approval (max 30 min)",
          LoginAndConfig: "Login & Setup your business:",
          BusinessProf: "A. Business profile",
          ChoosePrice: "B. Choose your Subscription Pricing",
          PdfMenu: "C. Add a PDF menu (optional)",
          SetupParams: "D. Setup parameters",
          GridMap: "E. Build your map",
          SetPrices: "F. Setup prices (for Beaches and Pools only)",
          AddEmployees: "G. Add employees",
          ReadyToBePublish: "Your business is ready to be published",
          Note: "Note: ",
          Note1: "In a maximum of 30 minutes you will receive a notification that your business is approved or rejected.",
          Note2: "If your location is approved, you're ready to use our Business app and, based on your selected price plan, your location is visible to customers.",
          

          topHeader: {
            home: "Home",
            about: "About",
            trustedBy: "Trusted By",
            pricing: "Pricing",
            contactUs: "Contact us",
          },

          header: {
            home: "Home",
            about: "About tabo",
            pricing: "Pricing",
            contactUs: "Contact us",
            login: "Sign In",
            signUp: "Register"
          },

          mainBanner: {
            mainTitle: "Travel Access Book Organize",
            title: "TABO",
            para1: "TABO helps you reserve sunbeds and lounge chairs, providing all the necessary information about beaches and pools, including facilities, prices, and real-time availability. Once at your sunbed, simply scan the QR code to access the menu, browse the options, and place your order seamlessly.",
            socialLabel1: "For The Clients:",
            socialLabel2: "For The Business:",
            xxx: "xxxxxxxxxxxxxxxxxxxxxxxx",
            up100: "Grow your business 100%",
            happyClient: "+2.3K users",
            a49: "5.0",
            k15Reviews: "",
            kReservations: "(+18K reservations)"
          },

          WhatIsTabo: {
            title1: "WHAT IS",
            title2: "TABO?",
            para1: "TABO is the ultimate marketplace for beach and pool reservations, providing seamless access to rental services in the hospitality industry.",
            para2: "TABO Client is the application for users who want to explore beaches and pools, view photo galleries, schedules, amenities, GPS directions, menus, prices, and book sunbeds or lounge chairs effortlessly.",
            para3: "TABO Business is the application designed for venues to manage their own reservations and those received from Tabo Client users, ensuring efficient booking operations and better customer experience.",
          },

          TrustedBy: {
            title1: "Trusted",
            title2: "By",
          },

          NewWhyTabo: {
            title1: "WHY",
            title2: "TABO?",
            subtitle1: "Flexibility",
            subtitle2: "Profesionalism",
            subtitle3: "Mobility",
            para1: "Seamless Reservation Management. Using the TABO Business application, you can manage both online and in-house reservations, including those made by phone. Since multiple employees may handle bookings, the app prevents overlapping reservations and scheduling conflicts.",
            para2: "Real-Time Notifications for Smooth Operations. The TABO Business app includes an advanced notification system, ensuring that all staff members are instantly alerted when a new reservation is made or modified on the booking grid.",
            para3: "Stay Informed & Optimize Staff Efficiency. TABO sends automatic notifications to employees about upcoming, ongoing, or ending reservations, helping venues manage their resources efficiently. The app also offers additional features designed to save time and streamline operations.",
          },

          Reinvented: {
            title1: "How it",
            title2: "works?",
            RegisterOn: "Register on",
            WaitingForAppr: "Wait for approval (max 30 min)",
            LoginAndConfig: "Login and configurate your business:",
            BusinessProf: "A. Business profile",
            ChoosePrice: "B. Choose price plan",
            PdfMenu: "C. Add a pdf menu (optional)",
            SetupParams: "D. Setup different parmaeters",
            GridMap: "E. Build your map",
            SetPrices: "F. Set prices (for beach and pool)",
            AddEmployees: "G. Add employees",
            ReadyToBePublish: "Your business is ready to be published.",
            Note: "Note",
            Note1: "In a maximum of 30 minutes you will receive a notification that your business is approved or rejected.",
            Note2: "If your location is approved, you're ready to use our Business app and, based on your selected price plan, your location is visible to customers.",
          },

          WhyTabo: {
            ourVision: "Our Vision",
            ourMission: "Mission",
            titlepara1: "MISSION",
            titlepara2: "VISION",
            titlepara3: "VALUES",
            para1: "Our mission is to empower beach and pool operators with smart automation tools, optimizing their operations and maximizing efficiency with minimal effort. Through seamless digital bookings and self-service ordering, we create a frictionless experience for both businesses and tourists.",
            para2: "We envision a future where technology eliminates waiting times and manual processes. In an increasingly digital world, self-service solutions and real-time management systems transform how beaches and pools operate, offering a faster, more convenient experience for both venues and guests.",
            para3: "We are driven by speed, efficiency, and innovation. Our goal is to streamline operations, enabling instant reservations and self-service ordering with minimal staff intervention. We value partnerships and trust, ensuring that businesses thrive through automation, transparency, and seamless customer experiences.",
          },

          OurServices: {
            title1: "Our",
            title2: "Services",
            img1: "Sunbeds or bads booking",
            img2: "Beaches and pools",
            img3: "Orders from the beach",
            img4: "Useful business informations",
          },

          OurApp: {
            title1: "Our",
            title2: "Apps",
            tab1: "Tabo Business",
            tab2: "Tabo Client",
            appSlider: {
              tab1: "Beaches and Pools",
              tab2: "Restaurants & Clubs"
            }
          },


          HowDoesTabo: {
            title1: "How TABO helps you with,",
            title2: "clients",
            text1: "The TABO client application is available for free on Google Play and the App Store, for anyone with a mobile phone",
            text2: "We can assure you, that more and more people know about TABO and your business.",
            text3: "Tens of thousands of people in your geographical area or interested in your services. You can see in real time the status, cost and quality of the services you provide",
          },

          ContactForm: {
            contactUs: "Contact",
            contactUs1: "us",
            yourmsg: "Your message",
            email: "Email",
            submit: "Send",
            ValidationError: "Invalid email format or message is empty",
            successMessage: "Thank you for contacting us. One of our team members will contact you!",
            serverError: "Internal Error, please try again later!"
          },

          Footer: {
            rights: "© 2024 TABO. All rights reserved.",
            rights1: "All rights reserved.",
            home: "Home",
            about: "About Tabo",
            TrustedBy: "Trusted by",
            pricing: "Pricing",
            contactUs: "Contact us",
            login: "Sign In",
            signUp: "Register",
            socialLabel1: "For Client",
            socialLabel2: "For business"
          },

          Beach: {
            infoBeach1: "This package is intended for those types of beaches that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of beaches that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of beaches that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 10%",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Set the reservations received from customers on the map",
            SetNotifications: "Presetting notifications according to your needs",
            StoreReservations: "Create and store your own bookings",
            DayOrRange: "Bookings can be created for a day, period or individual days",
            ProtocolDiscount: "Create bookings with protocol and/or discount",
            ChangeSwitchReserv: "Option to move reservations to other positions",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            GetStarted: "Choose",
          },

          Pool: {
            infoBeach1: "This package is intended for those types of pools that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of pools that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of pools that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 10%",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Set the reservations received from customers on the map",
            SetNotifications: "Presetting notifications according to your needs",
            StoreReservations: "Create and store your own bookings",
            DayOrRange: "Bookings can be created for a day, period or individual days",
            ProtocolDiscount: "Create bookings with protocol and/or discount",
            ChangeSwitchReserv: "Option to move reservations to other positions",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            GetStarted: "Choose",
          },

          RestaurantPlan: {
            infoBeach1: "This package is intended for those types of restaurants that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of restaurants that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of restaurants that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 1€",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Setup the reservations received from customers",
            AddAgenda: "Adding your own customers (Booklet)",
            SetNotifications: "Presetting notifications according to your needs",
            ServerNotifications: "Notifications 15 minutes before the start of the booking",
            BirthdayNotifications: "Notifications 30/15 days before birthdays",
            ManualorBulkSMS: "Send SMS while create a booking or scheduled",
            StoreReservations: "Create and store your own bookings",
            ShowName: "Feature to display customer names over the tables ",
            NumberOfReservations: "Feature to display number of bookings for the whole day",
            ColitionBlinking: "Collision warning for bookings",
            ChangeSwitchReserv: "Option to move bookings to other tables or areas",
            LateEditTime: "Option 'Customer Late' or 'Edit Occupancy Time'",
            HistoryNotifications: "Notification history for the current day",
            HistoryActions: "History of actions taken by your colleagues",
            MonitorReservations: "Section monitoring bookings in real time",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            InfoSMS: "Note: For SMS it is necessary to purchase credit, payment is made with the card in advance. Below you have the price list for each country",
            GetStarted: "Choose",
            FreeTrial: "Free trial: 1 month",
            IncludedSMS: "Included SMSs for free trial: 10",
          },

          Club: {
            infoBeach1: "This package is intended for those types of clubs that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of clubs that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of clubs that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 1€",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Setup the reservations received from customers",
            AddAgenda: "Adding your own customers (Booklet)",
            SetNotifications: "Presetting notifications according to your needs",
            ServerNotifications: "Notifications 15 minutes before the start of the booking",
            BirthdayNotifications: "Notifications 30/15 days before birthdays",
            ManualorBulkSMS: "Send SMS while create a booking or scheduled",
            StoreReservations: "Create and store your own bookings",
            ShowName: "Feature to display customer names over the tables ",
            NumberOfReservations: "Feature to display number of bookings for the whole day",
            ColitionBlinking: "Collision warning for bookings",
            ChangeSwitchReserv: "Option to move bookings to other tables or areas",
            LateEditTime: "Option 'Customer Late' or 'Edit Occupancy Time'",
            HistoryNotifications: "Notification history for the current day",
            HistoryActions: "History of actions taken by your colleagues",
            MonitorReservations: "Section monitoring bookings in real time",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            InfoSMS: "Note: For SMS it is necessary to purchase credit, payment is made with the card in advance. Below you have the price list for each country",
            GetStarted: "Choose",
            FreeTrial: "Free trial: 1 month",
            IncludedSMS: "Included SMSs for free trial: 10",
          },

          PricingPlan: {
            Beach: "Beach",
            Pool: "Pool",
            Restaurant: "Restaurant",
            Club: "Club",
            Plan: "Plan",
            Costs: "Costs"
          },
          Tutorials: {
            title: "Tutorials",
            Load: "Load More",
            reset: "Reset"
          }
        }
      },
      ro: {
        translations: {
          "booked": "Rezervat",
          "terms_condition": "Termeni si conditii",
          "submit_otp": "Vă rugăm să introduceți OTP primire pe mobilul de înregistrare",
          "otp_placeholder": "Vă rugăm să introduceți OTP",
          "cancel": "Anulare",
          "resendOtp": "Retrimiteți OTP",
          "submit": "Trimite",
          "warningAlert": "Vă recomandăm insistent să nu partajați acest link web cu alții. S-ar putea să folosească rezervarea pe cont propriu",
          "reservation_details": "Detalii rezervare",
          "qr_code": "Cod QR",
          "scan_text_warn": "Prezentați codul QR la sosire",
          "location": "Locație",
          "address": "Abordare",
          "contact_info": "Informatii de contact",
          "phone": "Telefon",
          "website": "Site-ul web",
          "menu": "Meniul",
          "our_menu": "vezi meniul nostru",
          "cuisine": "Bucătărie sau mâncare",
          "facilities": "Facilităţi",
          "agree_with": "De acord cu",
          "check_out": "Verifică",
          "reviews": "Recenzii",
          "total_review": "Total recenzii",
          "release": "Eliberare",
          close: "Închide",
          okay: "bine",
          reservation_id: "ID rezervare",
          zone: "Zona",
          start_at: "De la",
          end_at: "Pana la",
          not_allow_open: "Nu se permite deschiderea pe desktop, tabletă și telefoane pliabile",
          time: "Timp",
          guests: "Oaspeți",
          find_us: "Cum ne găsești",
          text_copy: "Text copiat în clipboard!",
          select_language: "Selecteaza limba",
          ambiance: "Ambianță",
          food: "Alimente",
          service: "Serviciu",
          wifi: "Wifi",
          credit_card: "Credit card",
          kids: "Loc de Joacă",
          private_parking: "Parcare privată",
          public_parking: "Parcare publică",
          power_by: "Powered by",
          read: "Citește",
          month: "lună",
          info1: "",
          info2: "",
          completed: "Terminată",
          released: "Terminată",
          canceled: "Anulată",
          expired: "Expirată",

          RegisterOn: "Înregistrează-te pe",
          WaitingForAppr: "Asteapta aprobarea (max 30 min)",
          LoginAndConfig: "Loghează-te & Setează-ți locația:",
          BusinessProf: "A. Pagina de profil",
          ChoosePrice: "B. Alege planul tarifar",
          PdfMenu: "C. Adaugă un meniu PDF (opțional)",
          SetupParams: "D. Setează parametrii localtiei",
          GridMap: "E. Crează harta locației",
          SetPrices: "F. Setează prețurile (doar pentru Plaje și Piscine)",
          AddEmployees: "G. Adaugă angajați",
          ReadyToBePublish: "Locația ta este gata să fie publicată",
          Note: "Notă: ",
          Note1: "În maxim 30 de minute vei primii o notificare cu privire la acceptarea sau respingerea solicitiarii.",
          Note2: "Dacă locația este aprobată, ești gata să folosești aplicația Business și, în funcție de planul tarifar ales, locația va fi listată în aplicația de client.",
          
          


          topHeader: {
            home: "Acasă",
            about: "Despre",
            trustedBy: "Parteneri",
            pricing: "Prețuri",
            contactUs: "Contactaţi-ne",
          },

          header: {
            home: "Acasă",
            about: "DESPRE TABO",
            pricing: "Tarife",
            contactUs: "Contactaţi-ne",
            login: "Loghează-te",
            signUp: "Inregistrează-te"
          },

          mainBanner: {
            mainTitle: "Travel Access Book Organize",
            title: "TABO",
            para1: "TABO te ajută să rezervi șezlonguri și paturi, oferindu-ți toate informațiile necesare despre plaje și piscine, inclusiv facilități, prețuri și disponibilitate în timp real. Odată ajuns la șezlong, scanează codul QR pentru a accesa meniul, a vedea opțiunile disponibile și a plasa comanda rapid și ușor.",
            socialLabel1: "Pentru Clienți",
            socialLabel2: "Pentru Afaceri:",
            up100: "Crește-ți afacerea 100%",
            happyClient: "+1.1K Users",
            a49: "5.0",
            k15Reviews: "",
            kReservations: "(+18k Rezervări)"
          },

          WhatIsTabo: {
            title1: "CE ESTE",
            title2: "TABO?",
            para1: "TABO este platforma ideală pentru rezervarea șezlongurilor și paturilor pe plajă și la piscină, oferind acces rapid la servicii de închiriere în industria ospitalității.",
            para2: "TABO Client este aplicația dedicată utilizatorilor care doresc să descopere plaje și piscine, să vadă galerii foto, program de funcționare, facilități, indicații GPS, meniuri, prețuri, și să rezerve șezlonguri sau paturi cu ușurință.",
            para3: "TABO Business este aplicația destinată locațiilor partenere pentru gestionarea propriilor rezervări, precum și a celor primite prin Tabo Client, asigurând un sistem eficient și o experiență mai bună pentru clienți.",
          },

          TrustedBy: {
            title1: "Parteneri",
            title2: "",
          },

          NewWhyTabo: {
            title1: "DE CE",
            title2: "TABO?",
            subtitle1: "Flexibilitate",
            subtitle2: "Profesionalism",
            subtitle3: "Mobilitate",
            para1: "Gestionare eficientă a rezervărilor. Prin aplicația TABO Business, poți administra atât rezervările online, cât și cele realizate telefonic sau la fața locului. Deoarece mai mulți angajați pot gestiona rezervările, aplicația previne suprapunerile și conflictele de programare.",
            para2: "Notificări în timp real pentru un flux de lucru fără probleme. Aplicația TABO Business include un sistem avansat de notificări, care asigură că toți angajații sunt anunțați imediat când se creează sau se modifică o rezervare în sistem.",
            para3: "Informații rapide și optimizare a resurselor. TABO trimite notificări automate angajaților cu privire la rezervările care urmează să înceapă, sunt în desfășurare sau se apropie de finalizare, ajutând locațiile să-și gestioneze eficient resursele. Aplicația oferă, de asemenea, funcționalități suplimentare pentru a economisi timp și a optimiza activitatea.",
          },

          WhyTabo: {
            ourVision: "Viziunea",
            ourMission: "Misiunea noastră",
            titlepara1: "MISIUNE",
            titlepara2: "VIZIUNE",
            titlepara3: "VALORI",
            para1: "Misiunea noastră este să oferim administratorilor de plaje și piscine instrumente digitale inteligente, optimizând operațiunile și maximizând eficiența cu efort minim. Prin rezervări rapide și comenzi self-service, creăm o experiență fluidă atât pentru afaceri, cât și pentru turiști.",
            para2: "Vedem un viitor în care tehnologia elimină timpii de așteptare și procesele manuale. Într-o lume din ce în ce mai digitalizată, soluțiile self-service și sistemele de gestionare în timp real revoluționează modul în care funcționează plajele și piscinele, oferind o experiență mai rapidă și mai convenabilă.",
            para3: "Ne ghidăm după viteză, eficiență și inovație. Scopul nostru este să simplificăm operațiunile, permițând rezervări instantanee și comenzi self-service cu intervenție minimă a personalului. Punem accent pe parteneriate și încredere, asigurând că afacerile prosperă prin automatizare, transparență și experiențe fluide pentru clienți.",
          },

          Reinvented: {
            title1: "Cum",
            title2: "functionează?",
            RegisterOn: "Register on",
            WaitingForAppr: "Wait for approval (max 30 min)",
            LoginAndConfig: "Login and configurate your business:",
            BusinessProf: "A. Business profile",
            ChoosePrice: "B. Choose price plan",
            PdfMenu: "C. Add a pdf menu (optional)",
            SetupParams: "D. Setup different parmaeters",
            GridMap: "E. Build your map",
            SetPrices: "F. Set prices (for beach and pool)",
            AddEmployees: "G. Add employees",
            ReadyToBePublish: "Your business is ready to be published.",
            Note: "Note: ",
            Note1: "In maximum 30 minutes u will get a notification that your business is approved or rejected.",
            Note2: "If your business is approved, you are ready to use our Business app and based on the price plan selected, your location is visible for clients.",
          },
  

          OurServices: {
            title1: "Serviciile",
            title2: "noastre",
            img1: "Rezervă șezlonguri sau baldachine",
            img2: "Plaje și piscine",
            img3: "Comenzi de la umbrelă",
            img4: "Informații utile despre locații"
          },

          OurApp: {
            title1: "Aplicațiile",
            title2: "noastre",
            tab1: "Tabo Business",
            tab2: "Tabo Client",
            appSlider: {
              tab1: "Plaje & Piscine",
              tab2: "Restaurante & Cluburi"
            }
          },


          HowDoesTabo: {
            title1: "Cum vă aduce Tabo,",
            title2: "clienți",
            text1: "Aplicația client TABO este disponibilă gratuit pe Google Play și App Store, pentru oricine are un telefon mobil.",
            text2: "Ne asigurăm că tot mai mulți oameni știu despre TABO și află despre afacerea ta.",
            text3: "Mii de persoane din zona dvs. geografică sau interesate de serviciile dvs. vad disponibilitatea locurilor, costul și calitatea serviciilor pe care le oferiţi.",
          },


          ContactForm: {
            contactUs: "Contactaţi",
            contactUs1: "-ne",
            yourmsg: "Mesajul Tău",
            email: "Email",
            submit: "Trimite",
            ValidationError: "Email invalid sau mesajul este gol",
            successMessage: "Mulțumim că ne-ai contactat. Un coleg vă va contacta în scurt time!",
            serverError: "Eroare internă, te rugăm să încerci mai târziu!"
          },

          Footer: {
            rights: "© 2024 TABO.Toate drepturile rezervate.",
            //rights1: "Toate drepturile rezervate",
            home: "Acasă",
            about: "DESPRE TABO",
            TrustedBy: "Parteneri",
            pricing: "Tarife",
            contactUs: "Contactaţi-ne",
            login: "Loghează-te",
            signUp: "Inregistrează-te",
            socialLabel1: "Pentru Clienți",
            socialLabel2: "Pentru Afaceri",
          },

          Beach: {
            infoBeach1: "Acest pachet este destinat acelor tipuri de piscine care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoBeach2: "Acest pachet este destinat acelor tipuri de piscine care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoBeach3: "Acest pachet este destinat acelor tipuri de piscine care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primita: 10%",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea pe hartă a rezervarilor primite de la clienți",
            SetNotifications: "Prestabilirea notificărilor in funcție de nevoi",
            StoreReservations: "Creați și stocați propriile rezervări",
            DayOrRange: "Rezervările se pot crea pe o zi, perioadă sau zile individuale",
            ProtocolDiscount: "Creare rezervări cu protocol și/sau discount",
            ChangeSwitchReserv: "Optiune mutare rezervări la alte poziții",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            GetStarted: "Alege",



          },

          Pool: {
            infoPool1: "Acest pachet este destinat acelor tipuri de plaje care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoPool2: "Acest pachet este destinat acelor tipuri de plaje care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoPool3: "Acest pachet este destinat acelor tipuri de plaje care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primita: 10%",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea pe hartă a rezervarilor primite de la clienți",
            SetNotifications: "Prestabilirea notificărilor in funcție de nevoi",
            StoreReservations: "Creați și stocați propriile rezervări",
            DayOrRange: "Rezervările se pot crea pe o zi, perioadă sau zile individuale",
            ProtocolDiscount: "Creare rezervări cu protocol și/sau discount",
            ChangeSwitchReserv: "Optiune mutare rezervări la alte poziții",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            GetStarted: "Alege",

          },

          RestaurantPlan: {
            infoRestaurant1: "Acest pachet este destinat acelor restaurante care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoRestaurant2: "Acest pachet este destinat acelor restaurante care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoRestaurant3: "Acest pachet este destinat acelor restaurante care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primită: 1€",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea la mese a rezervarilor primite de la clienți",
            AddAgenda: "Adăugarea propriilor clienți (Agenda)",
            SetNotifications: "Prestabilirea notificărilor in functie de nevoi",
            ServerNotifications: "Notificări cu 15min inainte de incepera rezervării",
            BirthdayNotifications: "Notificări cu 30/15 zile înainte zile de nastere",
            ManualorBulkSMS: "SMS-uri trimise la crearea rezervarii sau programate",
            StoreReservations: "Creați și stocați propriile rezervări",
            ShowName: "Funcție afișare generala a numelor clienților la mese ",
            NumberOfReservations: "Funcție afișare număr rezervări pentru toată ziua",
            ColitionBlinking: "Avertizor coliziune rezervări",
            ChangeSwitchReserv: "Optiune mutare rezervari la alte mese sau zone",
            LateEditTime: "Opțiune 'Clientul Întârzie' sau 'Editare timp ocupare'",
            HistoryNotifications: "Istoric notificări pentru ziua în curs",
            HistoryActions: "Istoric acțiuni întreprinse de colegii dumneavoastră",
            MonitorReservations: "Secțiune monitorizare rezervări in timp real",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            InfoSMS: "Notă: Pentru SMS-uri este necesar să achiziționați credit, plata se face cu cardul în prealabil. Mai jos aveți lista de prețuri pentru fiecare țară",
            GetStarted: "Alege",
            FreeTrial: "Testare gratuită: 1 lună",
            IncludedSMS: "SMS incluse pentru testare: 10",
          },

          Club: {
            infoClub1: "Acest pachet este destinat acelor cluburi care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoClub2: "Acest pachet este destinat acelor cluburi care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoClub3: "Acest pachet este destinat acelor cluburi care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primită: 1€",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea la mese a rezervarilor primite de la clienți",
            AddAgenda: "Adăugarea propriilor clienți (Agenda)",
            SetNotifications: "Prestabilirea notificărilor in functie de nevoi",
            ServerNotifications: "Notificări cu 15min inainte de incepera rezervării",
            BirthdayNotifications: "Notificări cu 30/15 zile înainte zile de nastere",
            ManualorBulkSMS: "SMS-uri trimise la crearea rezervarii sau programate",
            StoreReservations: "Creați și stocați propriile rezervări",
            ShowName: "Funcție afișare generala a numelor clienților la mese ",
            NumberOfReservations: "Funcție afișare număr rezervări pentru toată ziua",
            ColitionBlinking: "Avertizor coliziune rezervări",
            ChangeSwitchReserv: "Optiune mutare rezervari la alte mese sau zone",
            LateEditTime: "Opțiune 'Clientul Întârzie' sau 'Editare timp ocupare'",
            HistoryNotifications: "Istoric notificări pentru ziua în curs",
            HistoryActions: "Istoric acțiuni întreprinse de colegii dumneavoastră",
            MonitorReservations: "Secțiune monitorizare rezervări in timp real",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            InfoSMS: "Notă: Pentru SMS-uri este necesar să achiziționați credit, plata se face cu cardul în prealabil. Mai jos aveți lista de prețuri pentru fiecare țară",
            GetStarted: "Alege",
            FreeTrial: "Testare gratuită: 1 lună",
            IncludedSMS: "SMS incluse pentru testare: 10",
          },

          PricingPlan: {
            Beach: "Plajă",
            Pool: "Piscină",
            Restaurant: "Restaurant",
            Club: "Club",
            Plan: "Plan",
            Costs: "Costuri"
          },
          Tutorials: {
            title: "Tutoriale",
            Load: "Mai mult",
            reset: "Resetează"
          }
        }
      }
    },
    fallbackLng: "ro",
    lng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
