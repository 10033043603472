import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";
import VirtualizedSelect from "react-virtualized-select";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageTitle from "../../common/PageTitle";

import { PlanPriceData } from "../../../services/data";
import httpService from "../../../services/http";

import 'react-tabs/style/react-tabs.css';
import "react-multi-carousel/lib/styles.css";
import '../../../assets/common.css';
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'

import Info from "../../../assets/images/info.png";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const DesktopPlan = (props) => {
  const { planDetails, index, SMSPrices } = props;
  const { t } = useTranslation();
  const [planHeaderHeightStyle, setPlanHeaderHeightStyle] = useState({});

  const [selectedPrice, setSelectedPrice] = useState({});

  useEffect(() => {
    if (SMSPrices.length > 0) {
      const defaultPrice = SMSPrices.find(p => p?.label && p?.label.includes("United States"))
      setSelectedPrice(defaultPrice);
    }
  }, [SMSPrices]);

  useEffect(() => {
    const arr = document.querySelectorAll(".plan-header");
    if (arr.length) {
      let maxHeight = 0;
      arr.forEach((ele) => {
        if (ele.clientHeight > maxHeight) {
          maxHeight = ele.clientHeight;
        }
      });
      setPlanHeaderHeightStyle({ height: `${maxHeight}px` });
    }
  }, [document]);

  const {
    plan_data = [],
    plan_price = 0,
    plan_title = "",
    trial_data = {},
    plan_image = null,
    plan_btn_text = "",
    currency_symbol = "€",
    info_restaurant = "",
    primary_text_color = "",
    primary_border_color = "",
    coast_per_reservation = "",
    top_features = [],
    note_information
  } = planDetails;
  return (
    <div key={`${index}`} className="md:mx-5 mx-2 h-full !w-auto !block">
      <div className="flex flex-col h-full border-2 border-solid border-[#0b1b353d] rounded-xl text-center mb-0">
        <div
          className="plan-header"
          style={{ ...planHeaderHeightStyle }}
        >
          <div className="pt-4 flex relative items-center px-4 w-full justify-center">
            <div className="mr-2 w-[15%] absolute left-3">
              <img src={plan_image} alt={plan_title} className="w-full" />
            </div>
            <div className="flex items-center max-w-[75%]">
              <h3 className="text-[#6844F9] font-bold md:text-2xl text-lg">{plan_title}</h3>
              {
                info_restaurant ? (
                  <button
                    data-tip
                    data-for={`global-${index}`}
                    className="hover:bg-transparent ml-2"
                  >
                    <img src={Info} alt={"Plan Info"} className="w-4" />
                  </button>
                ) : null
              }
              <ReactTooltip
                type="light"
                place="bottom"
                aria-haspopup='true'
                id={`global-${index}`}
                className="opacity-100 max-w-[30vw]"
              >
                <div className="break-words">
                  {t(info_restaurant)}
                </div>
              </ReactTooltip>
            </div>
          </div>
          <div className={`mt-4 flex items-end justify-center`}>
            <div className={`flex justify-center ml-12 text-[${primary_text_color}]`}>
              <h2 className={`text-[40px] leading-[32px] md:leading-[50px] font-bold mb-0`}>{plan_price}{currency_symbol ? <span className="text-lg align-top mt-1 inline-block ml-1">{currency_symbol}</span> : null}</h2>
            </div>
            <div className="text-lg">/ {t("month")}</div>
          </div>
          {
            top_features.map(feature => (
              <div className={`flex items-center justify-center gap-2 flex-wrap ${feature.class}`}>
                {t(feature.key)}
              </div>
            ))
          }
        </div>
        <div className="px-6 my-3">
          {
            plan_data.map((p, ind) => (
              <div key={`${ind}`} className="flex items-baseline pb-2 text-left text-[#0B1B35] md:text-sm text-xs">
                {/* <img src={p.is_available ? CheckIcon : Cross} alt="icon" /> */}
                <span className={`${!p.is_available ? "line-through text-gray-400" : "no-underline"}`}>{t(p.description)}</span>
              </div>
            ))
          }
        </div>
        <div className="px-6 mt-auto mb-[10px]">
          {
            note_information && (
              <Fragment>
                <div className="mt-4 mb-2">
                  <div className={`flex items-baseline pb-2.5 text-left text-[#red] text-md md:text-sm flex-wrap ${note_information.class}`}>
                    {t(note_information.key)}
                  </div>
                </div>
                {
                  SMSPrices.length ? (
                    <div className="price-select-dropdown">
                      <VirtualizedSelect
                        searchable
                        clearable={false}
                        options={SMSPrices}
                        value={selectedPrice}
                        classNamePrefix="select"
                        className="text-left mb-4 custom-select"
                        name={`${index}.${plan_title}`}
                        onChange={(newValue) => setSelectedPrice(newValue)}
                      />
                    </div>
                  ) : null
                }
              </Fragment>
            )
          }
          
        </div>
      </div>
    </div>
  );
};

const MobilePlan = (props) => {
  const { planDetails, index, SMSPrices, tooltipRef } = props;
  const { t } = useTranslation();

  const [selectedPrice, setSelectedPrice] = useState({});

  useEffect(() => {
    if (SMSPrices.length > 0) {
      const defaultPrice = SMSPrices.find(p => p?.label && p?.label.includes("United States"))
      setSelectedPrice(defaultPrice);
    }
  }, [SMSPrices]);

  const {
    plan_data = [],
    plan_price = 0,
    plan_title = "",
    trial_data = {},
    plan_image = null,
    plan_btn_text = "",
    currency_symbol = "",
    info_restaurant = "",
    primary_text_color = "",
    primary_border_color = "",
    coast_per_reservation = "",
    top_features = [],
    note_information
  } = planDetails;
  return (
    <div
      key={`${index}`}
      className="flex flex-col border-2 border-solid border-[#0b1b353d] rounded-xl w-full md:max-w-[100%] max-w-[98%] text-center md:mr-5 mb-5 custom-carousel-item-class"
    >
      <div className="flex flex-col">
        <div className="pt-4 flex items-center justify-between px-4 gap-2 w-full">
          <div className="w-[25%] h-full">
            <img src={plan_image} alt={plan_title} className="mx-auto" />
          </div>
          <div className="w-[50%] h-full flex flex-col item-center justify-center">
            <h3 className="text-[#6844F9] font-bold md:text-2xl text-lg font-DMSans">{plan_title}</h3>
          </div>
          <div className="w-[25%] h-full flex flex-col justify-center">
            <button
              ref={tooltipRef}
              data-tip={t(info_restaurant)}
              className="hover:bg-transparent"
              onClick={() => {
                ReactTooltip.rebuild();
                ReactTooltip.show(tooltipRef.current)
              }}
            >
              <img src={Info} alt={"Plan Info"} className="w-4" />
            </button>
          </div>
        </div>
        <div className={`flex justify-center items-end text-[${primary_text_color}] font-DMSans`}>
          <div className={`flex justify-center ml-12 text-[${primary_text_color}]`}>
            <h2 className="md:text-[50px] text-[30px] leading-[32px] md:leading-[60px] font-bold">{plan_price}</h2>
            {currency_symbol ? <span className="text-base">{currency_symbol}</span> : null}
          </div>
          <div className="text-sm">/ {t("month")}</div>
        </div>
        <div className="px-4 mt-3 text-xs md:text-xs">
          {
            top_features.map(feature => (
              <div className={`flex items-center justify-center gap-2 flex-wrap ${feature.class}`}>
                {t(feature.key)}
              </div>
            ))
          }
        </div>
        <div className="px-3 my-4">
          {
            plan_data.map((p, ind) => (
              <div key={`${ind}`} className="flex items-baseline pb-2.5 text-left text-[#0B1B35] text-xs md:text-xs">
                {/* <img src={p.is_available ? CheckIcon : Cross} alt="icon" /> */}
                <span className={`${!p.is_available ? "line-through text-gray-400" : "no-underline"}`}>{t(p.description)}</span>
              </div>
            ))
          }
        </div>
      </div>
      <div className="px-4 font-DMSans mt-auto mb-[30px]">
        {
          note_information && (
            <Fragment>
              <div className="mt-4 mb-2">
                <div className={`flex items-baseline pb-2.5 text-left text-xs md:text-xs flex-wrap ${note_information.class}`}>
                  {t(note_information.key)}
                </div>
              </div>
              {
                SMSPrices.length ? (
                  <VirtualizedSelect
                    searchable
                    maxHeight={100}
                    clearable={false}
                    options={SMSPrices}
                    value={selectedPrice}
                    classNamePrefix="select"
                    className="text-left mb-4"
                    name={`${index}.${plan_title}`}
                    onChange={(newValue) => setSelectedPrice(newValue)}
                  />
                ) : null
              }
            </Fragment>
          )
        }
        <button type="button" className={`w-full border-2 border-solid border-[${primary_border_color}] hover:border-primary text-xs sm:text-xl font-medium text-[#0B1B35] hover:text-white py-4 hover:bg-primary rounded-full`}>
          {t(plan_btn_text)}
        </button>
      </div>
    </div>
  );
};

const PricingPlan = () => {
  const { t } = useTranslation();
  const [planData, setPlanData] = useState([]);
  const [SMSPrices, setSMSPrices] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tooltipRef = Array.from({ length: PlanPriceData.length }, () => useRef(null));

  const planButtonContainer = {
    marginTop: "auto",
    marginBottom: "30px"
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1301 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1300, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 340,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 60,
    }
  };

  useEffect(() => {
    setPlanData(PlanPriceData);
    getSMSPrice();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getSMSPrice = async () => {
    try {
      const { data = [] } = await httpService.get("/smsPrice");
      setSMSPrices(data.map(d => ({
        ...d,
        label: `${d.country} ($${d?.price} / sms)`,
        value: d.id,
      })));
    } catch (error) { }
  };

  const renderDesktopPlan = (planDetails, index) => (
    <DesktopPlan
      index={index}
      key={`${index}`}
      SMSPrices={SMSPrices}
      planDetails={planDetails}
    />
  );

  const renderMobilePlan = (planDetails, index) => (
    <MobilePlan
      index={index}
      key={`${index}`}
      SMSPrices={SMSPrices}
      planDetails={planDetails}
      tooltipRef={tooltipRef[index]}
    />
  );
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const renderPlans = (plan) => {
    const { data = [], id } = plan;
    return (
      <TabPanel key={`${id}`}>
        <div className="pt-12 font-DMSans px-3">
          <Slider {...sliderSettings}>
            {data.map(renderDesktopPlan)}
          </Slider>
        </div>
        {/* <div className="block lg:hidden mt-4">
          <Carousel
            showDots
            swipeable
            draggable
            partialVisible
            keyBoardControl
            infinite={false}
            autoPlay={false}
            responsive={responsive}
            additionalTransfrom={0}
            transitionDuration={1000}
            customTransition="all .5"
            itemClass="carousel-item-class"
            dotListClass="custom-dot-list-style"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            beforeChange={(nextSlide) => setActiveSlide(nextSlide)}
            containerClass={`carousel-container pb-[2px] pl-[33px] md:!pl-0`}
            sliderClass={
              `md:!pl-[165px] ${activeSlide == data.length - 1 ? "left-[-60px]" : ""} ${activeSlide == data.length - 1 ? "md:left-[-330px]" : ""}`
            }
            >        
            {data.map(renderMobilePlan)}
          </Carousel>
        </div> */}
      </TabPanel>
    );
  };

  return (
    <div className="w-full font-poppins mx-auto md:pb-40 pb-10 bg-white sm:mb-15" id="pricing-plan">
      <PageTitle title1={t("PricingPlan.Plan")} title2={t("PricingPlan.Costs")} />
      {
        planData.length > 0 ? (
          <div className="w-full flex justify-center mt-3 sm:mt-10">
            <Tabs className="2xl:max-w-[1300px] xl:max-w-6xl lg:max-w-4xl max-w-[400px] mx-auto w-full">
              <TabList className="flex justify-center px-5">
                <Tab className="border w-full text-center first-tab py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] md:w-36 customFirstRounded">{t("PricingPlan.Beach")}</Tab>
                <Tab className="border w-full text-center md:w-36 py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] rounded-none">{t("PricingPlan.Pool")}</Tab>
                <Tab className="border w-full text-center md:w-36 py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] rounded-none">{t("PricingPlan.Restaurant")}</Tab>
                <Tab className="border w-full text-center md:w-36 py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] customLastRounded">{t("PricingPlan.Club")}</Tab>
              </TabList>
              {planData.map(renderPlans)}
            </Tabs>
          </div>
        ) : null
      }
      <ReactTooltip
        type="light"
        effect="solid"
        scrollHide={true}
        className={"max-w-[50vw]"}
      />
    </div>
  );
};

export default PricingPlan;