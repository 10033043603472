import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "../../common/PageTitle";

import YellowStar from '../../../assets/images/yellow-star.png';
import GooglePlay from '../../../assets/images/Google-Play.png';
import AppStore from '../../../assets/images/App-Store.png';
import Banner from '../../../assets/images/joyful-guy.png';
import Message from '../../../assets/images/message.png';

import '../../../assets/common.css';

const MainBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="md:max-w-[1190px] mx-auto px-2.5 pb-10 md:pb-40 bg-white main-banner font-poppins">
      <PageTitle title2={t("mainBanner.mainTitle")} component="h1" />
      <div className="flex lg:flex-row flex-col-reverse items-center px-5">
        <div className="lg:w-6/12 w-full font-poppins pr-2">
          <h3 className="sm:text-3xl text-lg font-semibold sm:leading-loose leading-7 text-secondary">{t("mainBanner.title")}</h3>
          <p className="text-base sm:font-normal font-light text-black mb-2.5">
            {/* Tabo este adevăratul tău director adjunct */}
            {t('mainBanner.para1')}

          </p>
          <div className="mb-5">
            <p className="sm:text-[20px] text-base font-normal leading-7 text-black sm:mb-2.5 mb-1">{t('mainBanner.socialLabel1')}</p>
            <div className="flex items-center">
              <a href="https://play.google.com/store/apps/details?id=com.tabo" className="inline-block mr-5" target="_blank" rel="noopener noreferrer">
                <img className="h-8" src={GooglePlay} alt="Google Play" />
              </a>
              <a href="https://apps.apple.com/sz/app/tabo/id1580936893" target="_blank" className="inline-block" rel="noopener noreferrer">
                <img className="h-8" src={AppStore} alt="Google Play" />
              </a>
            </div>
          </div>
          <div>
            <p className="sm:text-[20px] text-base font-normal leading-7 text-black sm:mb-2.5 mb-1">{t("mainBanner.socialLabel2")}</p>
            <div className="flex items-center">
              <a href="https://play.google.com/store/apps/details?id=com.hostess&hl=en&gl=US" target="_blank" className="inline-block mr-5" rel="noopener noreferrer">
                <img className="h-8" src={GooglePlay} alt="Google Play" />
              </a>
              <a href="https://apps.apple.com/ro/app/tabo-business/id1534772063" target="_blank" className="inline-block" rel="noopener noreferrer">
                <img className="h-8" src={AppStore} alt="Google Play" />
              </a>
            </div>
          </div>
        </div>

        <div className="lg:w-6/12 md:w-96 sm:w-80 w-56 relative font-poppins">
          <div className="flex items-center md:px-6 px-2.5 md:py-4 py-1 sm:h-auto h-7 absolute top-4 rounded-xl bg-white lg:-left-7 md:-left-24 sm:-left-20 boxShadow md:w-[275px] sm:w-72 w-24">
            <div>
              <img className="sm:w-auto sm:h-auto w-3.5 h-3.5" src={Message} alt="Message" />
            </div>
            <div className="md:ml-5 ml-2 ">
              <p className="sm:text-sm text-[5px] font-bold md:leading-4 leading-0 text-black md:mb-3">{t("mainBanner.up100")}</p>
              <div className="flex items-center">
                <img className="sm:h-auto sm:w-auto w-1 h-1 sm:mt-0 -mt-0.5" src={YellowStar} alt="reservations"/>
                <span className="sm:text-xs text-[6px] font-bold sm:leading-3 leading-2 text-black sm:ml-2 ml-0.5">{t("mainBanner.a49")}</span>
                <span className="sm:text-xs text-[5px] font-medium sm:leading-3 leading-2 text-8a898e sm:ml-2 ml-0.5">{t("mainBanner.kReservations")}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center md:px-6 px-2.5 md:py-4 py-1 sm:h-auto h-7 absolute md:bottom-14 sm:bottom-4 bottom-8  rounded-xl bg-white lg:-left-20 md:-left-32 sm:-left-36 -left-8 boxShadow sm:w-52 w-20">
            <div className="">
              <p className="sm:text-sm text-[5px] font-bold sm:leading-4 leading-0 text-black md:mb-3">{t("mainBanner.happyClient")}</p>
              <div className="flex items-center ">
                <img className="sm:h-auto sm:w-auto w-1 h-1 sm:mt-0 -mt-0.5" src={YellowStar} alt="review"/>
                <span className="sm:text-xs text-[6px] font-bold sm:leading-3 leading-2 text-black sm:ml-2 ml-0.5">{t("mainBanner.a49")}</span>
                <span className="sm:text-xs text-[5px] font-medium sm:leading-3 leading-2 text-8a898e ml-2">{t("mainBanner.k15Reviews")}</span>
              </div>
            </div>
          </div>
          <div className="relative">
            <img className="lg:mx-0 mx-auto" src={Banner} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBanner;