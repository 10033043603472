import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./Slider.css";
import BeachMain from '../../../../assets/images/3D_Carousel/beach-main.png';
import BeachReservation from '../../../../assets/images/3D_Carousel/beach-reservation.png';
import BeachReservation2 from '../../../../assets/images/3D_Carousel/beach-reservation2.png';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import '../../../../assets/common.css';
import { useTranslation } from "react-i18next";

function ReactSimplyCarouselExample() {
  const [activeSlide, setActiveSlide] = useState(0);
  const { t } = useTranslation();

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1, slidesToSlide: 1, partialVisibilityGutter: 22 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1, slidesToSlide: 1, partialVisibilityGutter: 22 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1, slidesToSlide: 1, partialVisibilityGutter: 30 }
  };

  return (
    <div className='app-slider'>
      <Tabs className="w-full react-tabs-ul">
        {/* <TabList className="flex justify-center mb-4 items-center">
          <Tab className="border text-center flex items-center justify-center w-[152px] h-[37px] py-2.5 font-medium text-[10px] sm:text-xs text-[#15143966] customFirstRounded leading-[0px]">
            {t("OurApp.appSlider.tab1")}
          </Tab>
        </TabList> */}

        {/* Beach & Pool Booking Section */}
        <TabPanel>
          <div className="md:block md:w-full max-w-7xl mx-auto px-5 md:pt-24 bg-white sm:hidden">
            <div className="w-full">
              <section id="slider">
                <input type="radio" name="slider" id="s1" defaultChecked onClick={() => setActiveSlide(0)} />
                <input type="radio" name="slider" id="s2" onClick={() => setActiveSlide(1)} />
                <input type="radio" name="slider" id="s3" onClick={() => setActiveSlide(2)} />
                <div className='cards'>
                  <label htmlFor="s2" id="slide2">
                    <img src={BeachReservation} height="100%" alt="BeachReservation"/>
                  </label>
                  <label htmlFor="s1" id="slide1">
                    <img src={BeachMain} height="100%" alt="BeachMain"/>
                  </label>
                  <label htmlFor="s3" id="slide3">
                    <img src={BeachReservation2} height="100%" alt="BeachReservation2"/>
                  </label>
                </div>
              </section>
            </div>
          </div>

          {/* Mobile Carousel */}
          <div className="block md:hidden pb-8 h-full w-full max-w-7xl mx-auto px-5 md:pt-24 bg-white sm:pb-0 pt-6 pr-0 pl-0">
            <div className='services-carousel why-tabo-new service-top my-services mb-8 md:hidden sm:block'>
              <Carousel
                partialVisible={false} responsive={responsive} swipeable={true} draggable={true} showDots={true} infinite={true}
                autoPlay={false} autoPlaySpeed={1000} keyBoardControl={true} customTransition="all .5" transitionDuration={500}
                dotListClass="custom-dot-list-style" itemClass="slider-padding pl-15 pr-15" containerClass="carousel-container pl-[5px]"
                removeArrowOnDeviceType={["tablet", "mobile"]} beforeChange={(nextSlide) => setActiveSlide(nextSlide)}
              >
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachMain} alt="BeachMain"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachReservation} alt="BeachReservation"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachReservation2} alt="BeachReservation2"/>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default ReactSimplyCarouselExample;
